import {
  DashboardOutlined,
  UserOutlined,
  BarChartOutlined,
  ProjectOutlined,
  CrownOutlined,
  SolutionOutlined,
  PlayCircleOutlined,
  AudioOutlined,
  FileDoneOutlined,
  FileImageOutlined
} from "@ant-design/icons";
import {
  APP_PREFIX_PATH
} from "configs/AppConfig";

const admin_status = sessionStorage.getItem('admin_status');

const dashBoardNavTree = [{
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "admin",
    path: `${APP_PREFIX_PATH}/admin`,
    title: "Admins",
    icon: CrownOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "projectrequest",
    path: `${APP_PREFIX_PATH}/admin/projectrequest`,
    title: "Project Request",
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "project",
    path: `${APP_PREFIX_PATH}/project`,
    title: "Projects",
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "user",
  //   path: `${APP_PREFIX_PATH}/user`,
  //   title: "Users",
  //   icon: UserOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "reports",
    path: `${APP_PREFIX_PATH}/reports`,
    title: "Project Metadata",
    icon: BarChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const dashBoardNavTreeUser = [{
  key: "dashboard",
  path: `${APP_PREFIX_PATH}/dashboard`,
  title: "Dashboard",
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
},
{
  key: "project",
  path: `${APP_PREFIX_PATH}/project`,
  title: "All Projects",
  icon: ProjectOutlined,
  breadcrumb: false,
  submenu: [],
},
// {
//   key: "project_prompt",
//   path: `${APP_PREFIX_PATH}/project`,
//   title: "Prompt Projects",
//   icon: AudioOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: "project_conversation",
//   path: `${APP_PREFIX_PATH}/project`,
//   title: "Conversation Projects",
//   icon: PlayCircleOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: "project_translation",
//   path: `${APP_PREFIX_PATH}/project`,
//   title: "Translation Projects",
//   icon: FileDoneOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: "project_annotation",
//   path: `${APP_PREFIX_PATH}/project`,
//   title: "Annotation Projects",
//   icon: FileImageOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: "user",
//   path: `${APP_PREFIX_PATH}/user`,
//   title: "Users",
//   icon: UserOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
{
  key: "reports",
  path: `${APP_PREFIX_PATH}/reports`,
  title: "Project Metadata",
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [],
},
];

const NavigationConfig = admin_status === 'true' ? [...dashBoardNavTree] : [...dashBoardNavTreeUser];

export default NavigationConfig;